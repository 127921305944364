import { Box, Flex, Typography, Confirmation } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import withCollection from "areas/collection/hoc/with-collection"

import CollectionAd from "./collection-ad"
import CollectionProduct from "./collection-product"
import Placeholder from "./placeholder"

const Container = styled(Flex).attrs((props) => ({
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  ...props,
}))``

export const GridContainer = styled(Box)`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr;
  ${(props) => props.theme.mediaQueries.tablet`
    grid-template-columns: 1fr 1fr 1fr;
  `}
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const Wrapper = styled.div`
  font-size: 20px;
  margin: 2em 0;
  text-align: center;
  ul {
    list-style: none;
    li {
      a {
        text-decoration: underline !important;
      }
    }
  }
`
const genericSearchFail = (
  <Container data-cy="collection-not-result">
    <Typography fontSize="20px" my="2em" textAlign="center">
      Sorry, we could not find any results for your search. Please try a
      different search term.
    </Typography>
  </Container>
)

const InternalCollectionMain = ({
  addToWishlist,
  confirmAddToWishlist,
  gridView,
  newPageStartIndex,
  no_result_message,
  placeholders,
  products,
  showLoading,
}) => {
  let placeholderSwitch = placeholders
  let productSwitch = products

  if (productSwitch && productSwitch.length < 1 && !showLoading) {
    if (no_result_message && no_result_message != "") {
      try {
        no_result_message = no_result_message.replace(/href="#"/g, "")
        no_result_message = no_result_message.replace(
          /ea_seopatch="-/g,
          // eslint-disable-next-line quotes
          'href="/search?k='
        )

        let parser = new DOMParser()
        let el = parser.parseFromString(no_result_message, "text/html")

        let links = []
        Array.from(el.querySelectorAll("a")).map((a) => {
          let to = a.getAttribute("href")
          let text = a.innerText
          links.push({ text, to })
        })

        const messages = {
          links,
          title: `${no_result_message.split(":")[0]}:`,
        }

        return (
          <Container>
            <Wrapper>
              <Typography fontSize="1.3rem">{messages.title}</Typography>
              <ul>
                {messages.links.map((link) => (
                  <li key={link.text}>
                    <Link data-lc="products" to={link.to}>
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </Wrapper>
          </Container>
        )
      } catch (err) {
        console.log("Search parsing error: ", err)
        return genericSearchFail
      }
    } else {
      return genericSearchFail
    }
  }

  const newPageFocusRef = useRef()
  useEffect(() => {
    // Maintain focus on the first product of the new page
    // on Load More button click
    newPageFocusRef?.current?.focus()
  }, [newPageStartIndex, newPageFocusRef.current])

  const [showWishlistDialog, setShowWishlistDialog] = useState(false)
  const [productParams, setProductParams] = useState(false)
  let productsOut = []
  if (productSwitch) {
    productSwitch.forEach((product, index) => {
      if (product && product.item_type == "product") {
        productsOut.push(
          <CollectionProduct
            confirmWishlist={setShowWishlistDialog}
            inCollection
            index={index}
            key={`product-${product.style_code}-${index}`}
            newPageFocusRef={newPageFocusRef}
            product={product}
            setProductParams={setProductParams}
          />
        )
      } else if (product && product.item_type == "ad_block") {
        productsOut.push(
          <CollectionAd
            ad={product}
            index={index}
            key={`ad-product-${product.style_code}-${index}`}
            newPageFocusRef={newPageFocusRef}
          />
        )
      }
    })
  }

  return (
    <GridContainer data-cy="collection-main-products">
      {[...Array(placeholderSwitch)].map((_, i) => (
        <Placeholder gridView={gridView} index={i} key={`placeholder-${i}`} />
      ))}
      {productsOut}
      <Confirmation
        cancelText="Cancel"
        confirmText="Proceed"
        description="You have reached the maximum number of items on your wish list. Adding this item will remove the oldest from your list."
        onCancel={() => {
          confirmAddToWishlist(false)
          setShowWishlistDialog(false)
        }}
        onConfirm={() => {
          confirmAddToWishlist(true)
          setShowWishlistDialog(false)
          addToWishlist(productParams)
        }}
        show={showWishlistDialog}
        title="Add to Wish List"
        variant="squircle"
      />
    </GridContainer>
  )
}

InternalCollectionMain.propTypes = {
  addToWishlist: PropTypes.func,
  confirmAddToWishlist: PropTypes.func,
  data: PropTypes.object,
  gridView: PropTypes.string,
  message: PropTypes.string,
  newPageStartIndex: PropTypes.number,
  no_result_message: PropTypes.string,
  placeholders: PropTypes.number,
  products: PropTypes.array,
  showLoading: PropTypes.oneOf(["content", "full"]),
}

const CollectionMain = withCollection(InternalCollectionMain)

export default CollectionMain
