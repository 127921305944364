import { Box, Flex, MediaBox, Divider } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import WithCollection from "areas/collection/hoc/with-collection"
import SortMenu from "areas/collection2/sort-menu"
import IntersectionWrapper from "components/common/intersection-wrapper"
import Perks from "components/perks"
import { isTestMode, retry } from "helpers/application"
import { Route } from "helpers/sentry"
import fetchWishlist from "hoc/fetch-wishlist"

import Banner from "./banner"
import FilterAccordion from "./filter-accordion"
import FilterHeader from "./filter-header"
import Heading from "./heading"
import LoadMoreProducts from "./load-more-products"
import FilterAccordionLoading from "./loaders/filter-accordion-loading"
import ProductsLoading from "./loaders/products-loading"
import Products from "./products"
import SuperFilters from "./super-filters"

const CollectionDescription = loadable(
  () => retry(() => import("./collection-description")),
  { ssr: false }
)
const Slider = loadable(() => retry(() => import("./slider")), { ssr: false })
const FilterMobile = loadable(() => retry(() => import("./filter-mobile")), {
  ssr: false,
})
const FilterTags = loadable(() => retry(() => import("./filter-tags")), {
  ssr: false,
})

const MainSection = styled(Box)`
  opacity: ${(props) => (props.showLoading ? "0.2" : "1")};
`

const InternalCollection = ({
  currentPage,
  data,
  mainBanners,
  showLoading,
  totalPage,
}) => {
  const productAreaRef = React.useRef(null)

  useEffect(() => {
    if (!isTestMode) {
      dataLayer.push({
        collectionTitle: data.title,
        contentIds: data.product_result.slice(0, 9).map((p) => p.rm_style_code),
        contentName: data.mountedPath,
        contentType: "product",
        event: "view-collection",
        viewCollectionUniqueEventId:
          window.cache_free_ssr_data.unique_event_ids.collection,
      })
    }
  }, [])

  return (
    <>
      {mainBanners ? (
        <Box data-optimize="collection-banner">
          <Banner firstElement lazyload={false} medias={mainBanners} />
        </Box>
      ) : null}
      <Box containWide px={2}>
        <Heading />
        <MediaBox lessThan="desktop">
          <SuperFilters />
          <FilterTags />
        </MediaBox>
        <Divider
          bg="grey.10"
          display={{ _: "none", desktop: "block" }}
          mb="10px"
        />
        <Route exact path="/:collection">
          <div id="collection-scroll-back-point">
            <MediaBox lessThan="desktop">
              <FilterHeader />
            </MediaBox>
            <Flex justifyContent="center">
              {showLoading === "full" ? (
                <FilterAccordionLoading />
              ) : (
                <MediaBox
                  aria-label="Filters"
                  as="nav"
                  greaterThanOrEqual="desktop"
                  maxWidth="215px"
                  mr={4}
                  width="22%"
                >
                  <FilterAccordion />
                </MediaBox>
              )}

              <Box flex="1">
                <Box pt={{ _: 3, desktop: 0 }}>
                  <MediaBox lessThan="desktop">
                    <FilterMobile />
                  </MediaBox>

                  {showLoading && (
                    <ProductsLoading productAreaRef={productAreaRef} />
                  )}
                  <MediaBox greaterThanOrEqual="desktop">
                    <SuperFilters />
                    <Flex alignItems="center" justifyContent="end">
                      <FilterTags />
                      <SortMenu />
                    </Flex>
                    <Divider bg="grey.10" mb="10px" mt={1} />
                  </MediaBox>
                  <MainSection
                    aria-atomic
                    aria-live="polite"
                    minHeight={showLoading ? "250px" : ""}
                    ref={productAreaRef}
                    showLoading={showLoading}
                  >
                    <Products data={data} />
                  </MainSection>
                </Box>
              </Box>
            </Flex>

            <Box data-cy="collection-load-product" mb="110px" mt={4}>
              {currentPage < totalPage && (
                <LoadMoreProducts next={currentPage + 1} />
              )}
            </Box>
          </div>
        </Route>
      </Box>
      <IntersectionWrapper>
        <Slider />
        <Perks />
        <CollectionDescription />
      </IntersectionWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    mainBanners: state.collection.banners?.filter((b) => b.location === "main"),
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalCollection.propTypes = {
  currentPage: PropTypes.number,
  data: PropTypes.object,
  mainBanners: PropTypes.array,
  page: PropTypes.number,
  setExperiment: PropTypes.func,
  showLoading: PropTypes.oneOf(["content", "full"]),
  totalPage: PropTypes.number,
}

const Collection = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithCollection(InternalCollection))

export default fetchWishlist(Collection)
