import { Box, Image, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import SlickSlider from "dependencies/carousel"
import { trackSuperFiltersEvent } from "helpers/collection"
import useWindowDimensions from "helpers/window-dimensions"
import { hideScrollbar } from "styles/utils"

import { FilterLink } from "./super-filters"

export const GradientContainer = styled.div`
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 1;
`

const ItemsSlider = styled.div`
  display: flex;
  justify-content: ${(props) => (props.modal ? "space-between" : "flex-start")};
  overflow-x: scroll;
  overflow-y: hidden;
  ${hideScrollbar}
`
const Item = styled.div`
  cursor: pointer;
  flex-basis: 35%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  &:last-child {
    margin-right: 16px;
  }
  ${(props) => props.theme.mediaQueries.tablet`
    margin-left: 0;
    padding: 0 8px;
    &:last-child {
      margin-right: 0;
    }
  `}
  ${(props) => props.theme.mediaQueries.desktop`
  `}
`

const Arrow = styled(Image)`
  width: 45px;
`

const SliderWrapper = styled(SlickSlider)`
  button[aria-label="next"] {
    right: -24px;
  }

  button[aria-label="previous"] {
    left: -24px;
  }
`

const Slider = ({ data, frameAriaLabel, location }) => {
  const sliderRef = useRef(null)
  const { isTablet } = useWindowDimensions()

  const sliderSettings = {
    defaultControlsConfig: {
      nextButtonText: (
        <Arrow src={require("images/icons/arrow_whitebg_left.svg")} />
      ),
      prevButtonText: (
        <Arrow src={require("images/icons/arrow_whitebg_left.svg")} />
      ),
    },
    dots: false,
    frameAriaLabel,
    slidesToShow: isTablet ? 3 : 5,
    slidestoScroll: 1,
    speed: 300,
    wrapAround: true,
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = 0
    }
  }, [data])
  return (
    <Box contain mb={4} px={0}>
      <MediaBox greaterThanOrEqual="tablet">
        <SliderWrapper {...sliderSettings}>
          {data.links.map((item) => (
            <SliderItem
              item={item}
              key={`superfilters_slider_desktop${item.name}`}
              location={location}
            />
          ))}
        </SliderWrapper>
      </MediaBox>
      <MediaBox lessThan="tablet">
        <ItemsSlider ref={sliderRef}>
          {data.links.map((item) => (
            <SliderItem
              item={item}
              key={`superfilters_slider_${item.name}`}
              location={location}
            />
          ))}
        </ItemsSlider>
      </MediaBox>
    </Box>
  )
}

const SliderItem = ({ item, location }) => {
  return (
    <Item onClick={() => trackSuperFiltersEvent(location.pathname, item.url)}>
      <Link data-lc="slider" to={item.url}>
        <Image
          alt={item.name}
          mb={1}
          srcSet={`${sl.config.jewlr_asset_url}images/product_carousel/${item.image}.jpg 1x, ${sl.config.jewlr_asset_url}images/product_carousel/${item.image}_2x.jpg 2x`}
        />
      </Link>
      <FilterLink $canOverflow data-lc="slider" to={item.url} type="underline">
        {item.name}
      </FilterLink>
    </Item>
  )
}

Slider.propTypes = {
  data: PropTypes.object,
  frameAriaLabel: PropTypes.string,
  location: PropTypes.object,
}
SliderItem.propTypes = {
  item: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(Slider)
