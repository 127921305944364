import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import {
  loadReactCollection,
  replaceCollectionData,
  setLoading,
} from "areas/collection/store/actions"
import { getHreflangs } from "helpers/application"
import { checkCollectionUrl } from "helpers/collection/url-checker"

export default function fetchCollection(WrappedComponent) {
  const InternalFetchCollection = ({
    collectionData,
    collectionPath,
    currentPath,
    history,
    landingData,
    landingPath,
    loadCollection,
    location,
    match,
    model,
    setModel,
    ...props
  }) => {
    useEffect(() => {
      // this populates load the landing and collections if not ssr
      if (
        !model ||
        (model == "collection" &&
          (!collectionPath.startsWith(currentPath) || currentPath === "/")) ||
        (model == "landing" && landingPath !== currentPath) ||
        (model == "clear" &&
          (landingPath == currentPath ||
            collectionPath.startsWith(currentPath)))
      ) {
        const path = model == "landing" ? landingPath : collectionPath
        // We don't care about the host at all, but need to keep URL constructor happy
        const dummyHost = `${window.location.protocol}//${window.location.hostname}`
        const mountedUrl = new URL(`${dummyHost}${path}`)
        // There are scenarios where the mounted path will have a page number greater
        // than the current page number due to user scrolling. Because of this, we can
        // avoid reloading since higher mounted pages will include the current page num
        const mountedPage = mountedUrl.searchParams.get("page") || "1"
        mountedUrl.searchParams.delete("page")
        const currentUrl = new URL(`${dummyHost}${currentPath}`)
        const currentPage = currentUrl.searchParams.get("page") || "1"
        currentUrl.searchParams.delete("page")
        if (
          // paths do not match
          mountedUrl.pathname !== currentUrl.pathname ||
          // current page number is higher than mounted page number
          (currentPage != "" && currentPage > mountedPage)
        ) {
          // Use this to debug random collection reloads
          // console.log(
          //   "CONDITION CHECK",
          //   mountedUrl.pathname !== currentUrl.pathname,
          //   currentPage > mountedPage,
          //   mountedUrl.toString(),
          //   mountedPage,
          //   currentUrl.toString(),
          //   currentPage
          // )
          loadCollection()
        } else if (
          mountedUrl.pathname === currentUrl.pathname &&
          history.action === "PUSH"
        ) {
          loadCollection()
        } else if (
          history.action === "POP" &&
          mountedUrl.pathname === currentUrl.pathname
        ) {
          const checkURLResult = checkCollectionUrl(match.params.collection)
          if (checkURLResult !== null) {
            const modelToSet = checkURLResult ? "collection" : "landing"
            setModel(modelToSet)
          }
        } else if (
          history.action === "REPLACE" &&
          mountedUrl.pathname === currentUrl.pathname
        ) {
          const checkURLResult = checkCollectionUrl(match.params.collection)
          if (checkURLResult !== null) {
            const modelToSet = checkURLResult ? "collection" : "landing"
            setModel(modelToSet)
          }
          loadCollection()
        }
      }
    }, [match.params.collection, location.key])

    // useEffect(() => {
    //   // This effect is responsible for back/forward arrows
    //   // when paginating in the collection
    //   if (
    //     mountedPath != "" &&
    //     collectionPath.startsWith(history.location.pathname) &&
    //     history.action == "POP" &&
    //     history.location.search != "" &&
    //     isPaginated
    //   ) {
    //     // triggerReload(history.location.search)
    //   }
    // }, [history.location.search])

    let pageUrl = ""
    let canonicalUrl = ""
    let nextPageUrl = ""
    let prevPageUrl = ""
    let productItemList = []
    let seoTitle = ""
    let seoDescription = ""
    let breadcrumbList

    if (model == "collection") {
      pageUrl = collectionData.pagination.current_page
      productItemList = collectionData.product_result.map((product, index) => {
        return {
          "@type": "ListItem",
          position: index,
          url: `https://${sl.config.site_url}/products/${product.style_code}`,
        }
      })
      canonicalUrl = `${collectionData.mountedPath}`
      nextPageUrl = collectionData.pagination.next_page
      prevPageUrl = collectionData.pagination.prev_page
      seoTitle = collectionData.seo_title
      seoDescription = collectionData.seo_description

      breadcrumbList = collectionData.breadcrumb.map((item, i) => ({
        "@type": "ListItem",
        item: {
          "@id": `https://${sl.config.site_url}${item.url || `/${pageUrl}`}`,
          name: item.seo_title,
        },
        position: i + 1,
      }))
    } else if (
      model == "landing" &&
      !["homepage", "home-page-b"].includes(landingData.url_path)
    ) {
      pageUrl = landingData.url_path
      canonicalUrl = `/${pageUrl}`
      productItemList = landingData.products.map((product, index) => {
        return {
          "@type": "ListItem",
          position: index,
          url: `https://${sl.config.site_url}/products/${product.style_code}`,
        }
      })
      seoTitle = landingData.seo_title
      seoDescription = landingData.seo_description

      breadcrumbList = [
        {
          "@type": "ListItem",
          item: {
            "@id": `https://${sl.config.site_url}`,
            name: "Homepage",
          },
          position: 1,
        },
        {
          "@type": "ListItem",
          item: {
            "@id": `https://${sl.config.site_url}/${pageUrl}`,
            name: seoTitle,
          },
          position: 2,
        },
      ]
    }

    return (
      <>
        <Helmet>
          {canonicalUrl && (
            <link
              href={`https://${sl.config.site_url}${canonicalUrl}`}
              rel="canonical"
            />
          )}
          {prevPageUrl && (
            <link
              href={`https://${sl.config.site_url}/${prevPageUrl}`}
              rel="prev"
            />
          )}
          {nextPageUrl && (
            <link
              href={`https://${sl.config.site_url}/${nextPageUrl}`}
              rel="next"
            />
          )}
          {pageUrl &&
            getHreflangs(`/${pageUrl}`).map(({ href, hreflang }) => (
              <link
                href={href}
                hrefLang={hreflang}
                key={hreflang}
                rel="alternate"
              />
            ))}

          {seoTitle && <title>{seoTitle}</title>}
          {seoDescription && (
            <meta content={seoDescription} name="description" />
          )}
          {seoDescription && (
            <meta content={seoDescription} property="og:description" />
          )}
          {(model == "collection" || model == "landing") &&
            productItemList.length > 0 && (
              <script type="application/ld+json">
                {`{
                  "@context": "https://schema.org",
                  "@type": "ItemList",
                  "url": "https://${sl.config.site_url}/${pageUrl}",
                  "numberOfItems": ${productItemList.length},
                  "itemListElement": ${JSON.stringify(productItemList)}
              }`}
              </script>
            )}

          {breadcrumbList && (
            <script type="application/ld+json">
              {`{
                    "@context": "http://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": ${JSON.stringify(breadcrumbList)}
                }
          `}
            </script>
          )}
        </Helmet>

        <WrappedComponent
          collectionData={collectionData}
          history={history}
          landingData={landingData}
          match={match}
          model={model}
          {...props}
        />
      </>
    )
  }

  InternalFetchCollection.propTypes = {
    collectionData: PropTypes.object,
    collectionPath: PropTypes.string,
    currentPath: PropTypes.string,
    history: PropTypes.object,
    landingData: PropTypes.object,
    landingPath: PropTypes.string,
    loadCollection: PropTypes.func,
    loadCollectionData: PropTypes.func,
    loadLandingData: PropTypes.func,
    location: PropTypes.object,
    match: PropTypes.object,
    model: PropTypes.string,
    mountedPath: PropTypes.string,
    setModel: PropTypes.func,
  }

  const mapStateToProps = (state, ownProps) => {
    // Super-check to see if we're loading from the server and there's no Collection/Landing
    const collectionPath = state.collection.pagination?.current_page
    const mountedPath = state.collection.mountedPath
    const landingPath = state.landing.url_path
    const serverNotFound =
      !collectionPath && !landingPath && ownProps.match.url == ownProps.path
    let model = state.collection.model

    if (
      state.collection.model === "" ||
      ownProps.match.url === `/${landingPath}` ||
      (ownProps.match.url === "/" &&
        ["homepage", "home-page-b"].includes(landingPath))
    ) {
      model = state.landing.model
    }
    return {
      collectionData: state.collection,
      collectionPath: `/${collectionPath}`,
      currentPath: `${state.router.location.pathname}${state.router.location.search}`,
      landingData: state.landing,
      landingPath: ["homepage", "home-page-b"].includes(landingPath)
        ? "/"
        : `/${landingPath}`,
      model,
      mountedPath,
      pageNotFound: state.collectionUI.pageNotFound || serverNotFound,
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      loadCollection: () => {
        dispatch(setLoading("content"))
        dispatch(
          loadReactCollection({ url_path: ownProps.match.params.collection })
        )
      },
      loadCollectionData: () => {
        dispatch(
          loadReactCollection({
            data: ownProps.collectionData,
            url_path: ownProps.collectionPath,
          })
        )
      },
      loadLandingData: () => {
        dispatch(
          loadReactCollection({
            data: ownProps.landingData,
            url_path: ownProps.landingPath,
          })
        )
      },
      setModel: (model) => {
        dispatch(
          replaceCollectionData({
            model: model,
          })
        )
      },
      // triggerReload: search => {
      //   const query = URI.parseQuery(search)
      //   dispatch(reloadCollection(query, true))
      // }
    }
  }

  const FetchCollection = connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InternalFetchCollection))

  return FetchCollection
}
