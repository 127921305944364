import { Box, Flex } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { trackSuperFiltersEvent } from "helpers/collection"
import { hideScrollbar } from "styles/utils"

const Container = styled(Flex)`
  margin: 0 0 32px;
  ${(props) =>
    props.centerItems
      ? `
    justify-content: center;
  `
      : `
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-y: hidden;
  `}
  ${hideScrollbar}
  ${(props) => props.theme.mediaQueries.tablet`
    justify-content: center;
    overflow-x: auto;
    overflow-y: auto;
  `}
`
export const FilterLink = styled(Link)`
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  font-size: 15px;
  text-decoration: underline;
  white-space: ${(props) => (props.$canOverflow ? "wrap" : "nowrap")};
`
const Filter = styled(Link)`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.black};
  display: inline-block;
  font-size: 15px;
  padding: 4px 8px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
`

const InternalSuperFilters = ({ filters, location }) => {
  if (!filters) return ""
  if (filters.type === "links") {
    return (
      <Container centerItems={filters.links.length < 3} gutter={2}>
        {filters.links.map((filter, index) => (
          <Box
            key={`${index}super_filter${filter.url}`}
            onClick={() =>
              trackSuperFiltersEvent(location.pathname, filter.url)
            }
          >
            <FilterLink data-lc="super-filters" to={filter.url}>
              {filter.name}
            </FilterLink>
          </Box>
        ))}
      </Container>
    )
  } else if (filters.type === "filters") {
    return (
      <Container
        centerItems={filters.links.length < 3}
        gutter={{ _: 1, tablet: 2 }}
      >
        {filters.links.map((filter, index) => (
          <Box
            flex={{ _: "1 0 auto", tablet: "0 1 auto" }}
            key={`${index}super_filter${filter.url}`}
            width={{ _: "36%", tablet: "auto" }}
          >
            <Filter data-lc="super-filters" to={filter.url}>
              {filter.name}
            </Filter>
          </Box>
        ))}
      </Container>
    )
  } else {
    return ""
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.collection.super_filters,
  }
}

const mapDispatchToProps = () => {
  return {}
}

InternalSuperFilters.propTypes = {
  filters: PropTypes.object,
  location: PropTypes.object,
}

const SuperFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalSuperFilters)

export default withRouter(SuperFilters)
